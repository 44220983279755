import { FontIcon } from "@fluentui/react";
import stringsConst from "consts/strings";
import customTheme from "helpers/customTheme";

export function NoRequiredConstraints() {
	return (
		<div style={customTheme.noConstraintStyle}>
			<div style={customTheme.IconBackgroundStyle}>
				<FontIcon
					iconName="Info"
					style={customTheme.infoIconStyle}
				/>
			</div>
			<div>
				<span style={customTheme.noConstraintLableStyle}>{stringsConst.requiredConstraintsHeader.noConstraintTiltle}</span>
				<span><br/>{stringsConst.requiredConstraintsHeader.noConstraintDescription}</span>
			</div>
	  </div>
	);
}


