import stringsConst from "consts/strings";
import customTheme from "helpers/customTheme";

export function RequiredConstraintsHeader() {
	return (
		<>
			<div style={customTheme.lableStyle}>{stringsConst.requiredConstraintsHeader.title}</div>
			<>
				<span style={customTheme.textStyle}>{stringsConst.requiredConstraintsHeader.headingDescription}<br/><br/>
				{stringsConst.requiredConstraintsHeader.helpMassage}</span>
				<span style={customTheme.emailStyle}>{stringsConst.requiredConstraintsHeader.email}</span>
			</>
		</>
	);
}


