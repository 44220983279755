import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { AppConfigs } from "../config/appConfigs";


export class TelemetryContextProvider {
    private static instance: TelemetryContextProvider;

    private appInsights: ApplicationInsights;

    private reactPlugin : ReactPlugin;

    private constructor() {

    const browserHistory = createBrowserHistory({ basename: '' });
     this.reactPlugin = new ReactPlugin();
     this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: AppConfigs().instrumentationKey,
        disableFetchTracking: false,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAutoRouteTracking: true,
        extensions: [this.reactPlugin as ITelemetryPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    this.appInsights.loadAppInsights();


  }

  public static getAppInsightInfo() {
    if (!TelemetryContextProvider.instance) {
        TelemetryContextProvider.instance=new TelemetryContextProvider();
    }

    return TelemetryContextProvider.instance;
  }

  public  getAppInsightInfoInstance=():ApplicationInsights => this.appInsights;

  public  getReactPluginInstance=():ReactPlugin => this.reactPlugin;
}