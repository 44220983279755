import { ReactNode } from "react";
import { Stack, StackItem, Text } from "@fluentui/react";
import { CardStyles } from "./Card.styles";

export interface CardProps {
  title?: string;
  titleClass?: string;
  children?: ReactNode[];
  childGap?: number;
}

export function Card(props: CardProps) {
  const { title, children, childGap, titleClass } = props;
  return (
    <div className={CardStyles.card}>
      <Stack tokens={{ childrenGap: childGap }}>
        <Text className={titleClass}>{title}</Text>
        {children?.map((child, i) => (
          <StackItem key={`stack item #${i}`} className={CardStyles.cardChildren}>{child}</StackItem>
        ))}
      </Stack>
    </div>
  );
}
