import {
  IComboBoxOption,
  mergeStyleSets,
  Panel,
  PanelType as FluentPanelType,
  Text,
} from "@fluentui/react";
import PanelHeader from "components/shared/panel/PanelHeader";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAllConstraintsForEditConstraintQuery } from "services/constraint";
import _ from "lodash";
import { ISlotConstraint, ITemplateRevisionSlot } from "models/slot";
import {
  ConstraintOperator,
  IConstraintTemplateEditPanel,
} from "models/constraints";
import useObserver from "hooks/useObserver";
import stringsConst from "consts/strings";
import useSlotInfoProvider from "hooks/template/useSlotInfoProvider";
import ConstraintEditList from "./ConstraintEditList";
import ConstraintAdder from "./ConstraintAdder";
import ConstraintEditPanelActions from "./ConstraintEditPanelActions";
import { useTemplateEditStructureNodeManager } from "../structure/TemplateEditStructureNodeManager";

const ConstraintEditPanelStyles = mergeStyleSets({
  p: {
    marginTop: 11,
    fontSize: 12,
    width: 400,
  },
  ButtonContainer: {
    marginTop: 27,
    width: 150,
  },
});

export type ConstraintEditPanelProps = {
  templateId?: string;
  revisionId?: string;
  closePanel: () => void;
};

function ConstraintEditPanel({
  closePanel,
  templateId,
  revisionId,
}: ConstraintEditPanelProps) {
  const [options, setOptions] = useState<
    IConstraintTemplateEditPanel[] | undefined
  >([]);
  const { data: filteredItems, isLoading } =
    useGetAllConstraintsForEditConstraintQuery();
  const { nodeTree, getSelectedNodes } = useTemplateEditStructureNodeManager();
  const nodeTreeRefreshToken = useObserver(nodeTree.observable); // Need to update used constraints if node tree was refreshed
  const slotRef = useRef<ITemplateRevisionSlot>();

  const [commonConstraints, setCommonConstraints] = useState<ISlotConstraint[]>(
    []
  );
  const [constraintsEdit, setConstraintsEdit] = useState<ISlotConstraint[]>([]);
  const [usedConstraints, setUsedConstraints] = useState<string[]>([]);
  const [slotConstraintsArray, setSlotConstraintsArray] = useState<
    { slot: ITemplateRevisionSlot; constraints: ISlotConstraint[] }[]
  >([]);
  const [noValues, setNoValues] = useState(false);
  const selectedNodes = getSelectedNodes();
  const allSelectedSlots = selectedNodes.map((n) => n.content!);
  const { slotInfoResponse, isLoadingSlot } = useSlotInfoProvider(
    templateId!,
    revisionId!,
    allSelectedSlots
  );

  function collectCommonConstraints(
    slotConstraints: {
      slot: ITemplateRevisionSlot;
      constraints: ISlotConstraint[];
    }[]
  ): ISlotConstraint[] {
    // Step 1: Flatten all constraints into a single array
    const allConstraints = slotConstraints.flatMap((sc) => sc.constraints);

    // Step 2: Count the occurrences of each keyId
    const keyIdCounts: { [keyId: string]: number } = {};

    allConstraints.forEach((constraint) => {
      if (constraint.keyId !== undefined) {
        keyIdCounts[constraint.keyId] =
          (keyIdCounts[constraint.keyId] || 0) + 1;
      }
    });

    // Step 3: Filter the keys that appear in every slotConstraints entry
    const commonKeyIds = Object.keys(keyIdCounts).filter(
      (keyId) => keyIdCounts[keyId] === slotConstraints.length
    );

    // Step 4: Map the common keyIds back to their corresponding ISlotConstraint objects
    const includedKeyIds = new Set<string>();
    const filteredConstraints = allConstraints.filter((constraint) => {
      if (
        constraint.keyId !== undefined &&
        commonKeyIds.includes(constraint.keyId) &&
        !includedKeyIds.has(constraint.keyId)
      ) {
        includedKeyIds.add(constraint.keyId);
        return true;
      }
      return false;
    });
    return filteredConstraints;
  }

  useEffect(() => {
    if (!isLoading && filteredItems) {
      const constraintItems = filteredItems?.map((obj) => ({
        ...obj,
        key: obj.name,
        text: obj.display,
      }));
      setOptions(constraintItems);
    }
  }, [filteredItems, isLoading]);

  useEffect(() => {
    const firstSelectedNode = getSelectedNodes()[0];
    const slotConstraints:
      | { slot: ITemplateRevisionSlot; constraints: ISlotConstraint[] }[]
      | undefined = [];
    if (!isLoadingSlot) {
      if (firstSelectedNode && slotInfoResponse) {
        selectedNodes.forEach((selectedNode) => {
          const matchingResponse = slotInfoResponse.find(
            (response) => response.id === selectedNode.id
          );

          if (matchingResponse !== undefined) {
            slotRef.current = matchingResponse;
            slotConstraints.push({
              slot: slotRef.current!,
              constraints: slotRef.current!.constraints,
            });
          }
        });
        if (slotRef.current && slotConstraints.length > 0) {
          setSlotConstraintsArray(slotConstraints);
        }

        if (slotConstraints && slotConstraints.length > 1) {
          const commonConstraintsInSlots =
            collectCommonConstraints(slotConstraints);
          setConstraintsEdit(commonConstraintsInSlots);
          setCommonConstraints(commonConstraintsInSlots);
        } else setConstraintsEdit(slotConstraints[0]?.constraints);
      }
    }
  }, [nodeTreeRefreshToken, getSelectedNodes, isLoadingSlot]);

  useEffect(() => {
    if (constraintsEdit) {
      setUsedConstraints(constraintsEdit.map((c) => c.key));
    }
  }, [constraintsEdit, setUsedConstraints]);

  const Header = useCallback(() => {
    const title = slotRef.current?.partName || slotRef.current?.name;
    return <PanelHeader title={title} onClosePanel={closePanel} />;
  }, [closePanel]);

  const Footer = useCallback(() => {
    const isDisabled =
      !constraintsEdit?.every((c) => c.value !== "") ||
      _.isEqual(slotRef.current?.constraints, constraintsEdit);

    let updatedSlotConstraintsArray = [];
    if (slotConstraintsArray.length === 1) {
      updatedSlotConstraintsArray = [
        { slot: slotConstraintsArray[0].slot, constraints: constraintsEdit },
      ];
    } else {
      updatedSlotConstraintsArray = slotConstraintsArray.map(
        (slotConstraint) => {
          // Constraints that exist in slotConstraint.constraints but not in commonConstraints
          const uncommonConstraints = slotConstraint.constraints.filter(
            (constraint) =>
              !commonConstraints.some(
                (common) => common.keyId === constraint.keyId
              )
          );

          // Constraints that exist in both commonConstraints and constraintsEdit
          const existingConstraints = commonConstraints.filter((common) =>
            constraintsEdit.some((updated) => updated.keyId === common.keyId)
          );

          // Constraints that exist in constraintsEdit but not in commonConstraints
          const newConstraints = constraintsEdit.filter(
            (updated) =>
              !commonConstraints.some(
                (common) => common.keyId === updated.keyId
              )
          );

          // Combine all constraints
          const allConstraints = [
            ...uncommonConstraints,
            ...existingConstraints,
            ...newConstraints,
          ];

          // Keep track of included keyIds
          const includedKeyIds = new Set<string>();

          // Filter allConstraints to include only unique constraints
          const uniqueConstraints = allConstraints.filter((constraint) => {
            if (
              constraint &&
              constraint.keyId &&
              !includedKeyIds.has(constraint.keyId)
            ) {
              includedKeyIds.add(constraint.keyId);
              return true;
            }
            return false;
          });

          return {
            ...slotConstraint,
            constraints: uniqueConstraints,
          };
        }
      );
    }
    return (
      <ConstraintEditPanelActions
        slotConstraints={updatedSlotConstraintsArray}
        onClosePanel={closePanel}
        disabled={isDisabled}
        noValues={noValues}
      />
    );
  }, [slotConstraintsArray, constraintsEdit, closePanel]);

  const onConstraintUpdated = useCallback(
    (
      modifiedConstraint: ISlotConstraint,
      constraintIndex: number,
      remove?: boolean
    ) => {
      setConstraintsEdit((previousConstraints) => {
        const clonedConstraints = _.cloneDeep(previousConstraints);

        clonedConstraints[constraintIndex] = modifiedConstraint;

        if (remove) {
          clonedConstraints.splice(constraintIndex, 1);
          setTimeout(() => {
            setNoValues(false);
          }, 0);
          return clonedConstraints;
        }

        const selectedconst = filteredItems?.find(
          (i) => i.name === clonedConstraints[constraintIndex].key
        );

        clonedConstraints[constraintIndex].keyId = selectedconst?.key as string;
        return clonedConstraints;
      });
    },
    [setConstraintsEdit, filteredItems]
  );

  const onConstraintNoValues = useCallback(() => {
    setNoValues(true);
  }, [setConstraintsEdit]);

  const addConstraints = useCallback(
    (option?: IComboBoxOption | undefined) => {
      const selected = option?.selected;
      setConstraintsEdit((previousConstraints) => {
        const clonedConstraints = _.cloneDeep(previousConstraints);
        const currInd = clonedConstraints.findIndex(
          (t) => t.key === option!.key
        );
        if (!selected) {
          clonedConstraints.splice(currInd, 1);
        } else {
          const selectedconst = filteredItems?.find(
            (i) => i.name === option.key
          );
          clonedConstraints.push({
            key: option?.key as string,
            operator: ConstraintOperator.Include,
            value: "",
            valueDisplay: "",
            keyId: selectedconst?.key as string,
            keyDisplay: option?.text,
          });
        }
        setNoValues(false);
        return clonedConstraints;
      });
    },
    [setConstraintsEdit, filteredItems]
  );

  return (
    <Panel
      data-automation-id="templateEdit-structure-editConstraints-panel"
      isOpen
      styles={{
        main: { zIndex: 15 },
        content: { height: "calc(100vh - 262px)" },
      }}
      hasCloseButton={false}
      isFooterAtBottom
      type={FluentPanelType.custom}
      customWidth="643px"
      closeButtonAriaLabel={stringsConst.common.close}
      onRenderHeader={Header}
      onRenderFooter={Footer}
      overlayProps={{
        style: { zIndex: 10 },
      }}
      layerProps={{
        hostId: "TemplateEditStructureLayer",
        insertFirst: true,
        eventBubblingEnabled: true,
        onLayerWillUnmount: () => {
          document.body.style.removeProperty("overflow");
        },
        onLayerDidMount: () => {
          document.body.style.overflow = "hidden";
        },
      }}
    >
      <Text block className={ConstraintEditPanelStyles.p}>
        {stringsConst.templateEdit.constraint.theClauseWillBeRendered}
      </Text>
      <div className={ConstraintEditPanelStyles.ButtonContainer}>
        <ConstraintAdder
          usedConstraints={usedConstraints}
          constraintsList={options}
          onConstraintSelected={addConstraints}
        />
      </div>
      {constraintsEdit && constraintsEdit.length !== 0 && (
        <ConstraintEditList
          constraints={constraintsEdit}
          constraintsList={options}
          usedConstraints={usedConstraints}
          onConstraintUpdated={onConstraintUpdated}
          onConstraintNoValues={onConstraintNoValues}
        />
      )}
    </Panel>
  );
}

export default ConstraintEditPanel;
