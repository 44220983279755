import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { AuthClientProvider } from "services/client";

function AuthenticationContext({ children }: { children?: React.ReactNode }) {
  return (
    <MsalProvider instance={AuthClientProvider.getInstance().getPublicClientInstance()}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default AuthenticationContext;