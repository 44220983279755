import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { businessUnitActions } from "store/businessUnitSlice";
import store, { RootState } from "store";
import stringsConst from "consts/strings";
import { useTrackingContext } from "components/shared/TrackingContext";
import { clauseApi } from "services/clause";
import { constraintApi } from "services/constraint";
import { templateApi } from "services/template";
import { customClauseApi } from "services/customClause";
import { slotApi } from "services/slot";
import { clauseLabelApi } from "services/clauseLabel";
import { IRouteInfo } from "router";
import { businessUnitsActions } from "store/businessUnitsSlice";
import { AuthClientProvider } from "services/client";
import useDialog from "./useDialog";
import useRouter from "./useRouter";

const useBusinessUnit = (ifInitialize: boolean = false) => {
  const [isInitialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const authClient = AuthClientProvider.getInstance();
  // Set business units based on auth token roles when initializing
  useEffect(() => {
      if(ifInitialize && !isInitialized) {
        dispatch(businessUnitsActions.setBusinessUnits(authClient.getCurrentUserClaimRoles()));
      }
  },[ifInitialize]);
  
  const { openErrorDialog } = useDialog();

  const { trackEvent } = useTrackingContext();
  const { updateRoute, getRouteInfo } = useRouter();
  const businessUnitName = useSelector(
    (state: RootState) => state.businessUnit
  );
  
  useEffect(() => {
    const bu = store.getState().businessUnits;
    const hasValidBusinessUnit =  bu && bu.length > 0;
    if (hasValidBusinessUnit && ifInitialize && !isInitialized) {
      trackEvent(`${businessUnitName}- Current Business unit`);
      dispatch(businessUnitActions.setBusinessUnit(bu[0]));
      setInitialized(true);
    } 

    if (!hasValidBusinessUnit) {
      const errorMessage = stringsConst.dialogs.ErrorNoRole.message;
      openErrorDialog(errorMessage);
    }
  }, [
    ifInitialize,
    trackEvent,
    openErrorDialog,
    isInitialized
  ]);

  const { businessUnits } = store.getState();
  const setBusinessUnit = (bu: string) => {
    const routeInfo = getRouteInfo();
    const newRouteInfo: IRouteInfo = {
      component: routeInfo!.component,
      objectIdInfo: { isNothingSelected: true },
    };
    updateRoute(newRouteInfo, () => {
      dispatch(businessUnitActions.setBusinessUnit(bu));
      trackEvent(`${bu} Business unit selected`);

      // Trigger re-fetch for cached data tied to previous business unit
      dispatch(clauseApi.util.resetApiState());
      dispatch(constraintApi.util.resetApiState());
      dispatch(templateApi.util.resetApiState());
      dispatch(customClauseApi.util.resetApiState());
      dispatch(slotApi.util.resetApiState());
      dispatch(clauseLabelApi.util.resetApiState());
    });
  };

  const isProfessionalServices = (): boolean =>
    businessUnitName === "Professional Services";

  return {
    businessUnits,
    businessUnitName,
    setBusinessUnit,
    isProfessionalServices,
  };
};

export default useBusinessUnit;
