import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { Text } from "@fluentui/react/lib/Text";
import stringsConst from "consts/strings";
import useDialog from "hooks/useDialog";
import useRouter from "hooks/useRouter";
import { IDialogContentComponent } from "models/dialogs";
import { useTrackingContext } from "components/shared/TrackingContext";

const Error412Dialog = forwardRef(
  (_props: any, ref: ForwardedRef<IDialogContentComponent>) => {
    const { refresh } = useRouter();
    const { closeDialog } = useDialog();
    const { trackEvent } = useTrackingContext();
    useImperativeHandle(ref, () => ({
      onProceed: () => {
        refresh();
        closeDialog();
      },
    }));
    trackEvent(`Data out of sync Error412Dialog`);
    return <Text>{stringsConst.dialogs.Error412.message}</Text>;
  }
);

export default Error412Dialog;
