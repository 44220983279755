import { IContextualMenuProps } from "@fluentui/react/lib/ContextualMenu";
import { IButtonStyles, IconButton, IIconProps } from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";
import strings from "consts/strings";
import { useState } from "react";

export function HelpButton() {
  const [iconButtonHovered, setIconButtonHovered] = useState(false);
  const settingsIcon: IIconProps = { iconName: "Settings" };

  const IconButtonHoverFalse: IButtonStyles = {
    icon: {
      color: "#FFFFFF",
      fontSize: 12,
      position: "relative",
      backgroundColor: "#002050",
    },
  };

  const IconButtonHoverTrue: IButtonStyles = {
    icon: {
      color: "#FFFFFF",
      fontSize: 12,
      position: "relative",
    },
  };

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: "help",
        name: "Help",
        ariaLabel: "Help",
        iconProps: { iconName: "StatusCircleQuestionMark" },
        target: "_blank",
        href: strings.HelpPageLink,
      },
      {
        key: "privacy",
        name: "MS Privacy Notice",
        ariaLabel: "Privacy",
        iconProps: { iconName: "RedEye" },
        target: "_blank",
        href: strings.PrivacyLink,
      },
    ],
  }));

  return (
    <div>
      <IconButton
        iconProps={settingsIcon}
        title="Privacy"
        ariaLabel="Privacy"
        name="Help Links"
        styles={iconButtonHovered ? IconButtonHoverTrue : IconButtonHoverFalse}
        menuProps={menuProps}
        onMouseEnter={() => setIconButtonHovered(true)}
        onMouseLeave={() => setIconButtonHovered(false)}
      />
    </div>
  );
}
