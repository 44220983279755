import { mergeStyleSets } from "@fluentui/merge-styles";
import { FontSizes, FontWeights } from "@fluentui/react";

const MAX_WIDTH = 550;

export const AccessRequestStyles = mergeStyleSets({
  main: { backgroundColor: "#faf9f8" },
  heading: {
    display: "flex",
    flexDirection: "row",
    text: "rgb(50,49,48)",
    paddingTop: 35,
    paddingLeft: 50,
  },
  headTitle: {
    color: "rgb(50,49,48)",
    fontSize: FontSizes.xxLarge,
    fontWeight: FontWeights.semibold,
  },
  descriptionMain: {
    marginTop: 12,
    maxWidth: "90%",
    display: "inline-block",
  },
  xLargeIcon: {
    top: 6,
    color: "#a80000",
    fontSize: FontSizes.xxLarge,
    position: "relative",
  },
  roleDropDown: {
    width: 300,
  },
  submitButtonStyle: {
    maxWidth: 140,
  },
  list: {
    paddingLeft: 20,
    margin: 0,
  },
  mainStackItems: {
    maxWidth: MAX_WIDTH,
  },
});
