import { Icon, Stack } from "@fluentui/react";

export interface IconHeadingProps {
  iconName: string;
  iconClassName: string;
  title?: string;
  titleClassName?: string;
}

export function IconHeading(props: IconHeadingProps) {
  const { iconName, iconClassName, title, titleClassName } = props;
  return (
    <Stack tokens={{ childrenGap: 12 }} horizontal>
      <Icon iconName={iconName} className={iconClassName} />
      <span className={titleClassName}>{title}</span>
    </Stack>
  );
}