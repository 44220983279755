import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";

const initialState: string[] = [];

export const businessUnitsSlice = createSlice({
  name: "businessUnits",
  initialState,
  reducers: {
    setBusinessUnits(
      _state: WritableDraft<string[]>,
      action: PayloadAction<string[]>
    ) {
      return action.payload;
    }
  },
});

export const businessUnitsActions = businessUnitsSlice.actions;