import {
  DefaultEffects,
  FontSizes,
  FontWeights,
  mergeStyleSets,
} from "@fluentui/react";

const CHILD_MARGIN = 10;

export const CardStyles = mergeStyleSets({
  card: {
    boxShadow: DefaultEffects.elevation8,
    backgroundColor: "white",
    maxWidth: "80%",
    marginLeft: 50,
    padding: 20,
    borderRadius: 10,
    selectors: {
      ":last-child": {
        marginBottom: 40
      },
    },
  },
  cardTitleMedium: {
    color: "rgb(50,49,48)",
    fontSize: FontSizes.mediumPlus,
    fontWeight: FontWeights.semibold,
    marginLeft: CHILD_MARGIN,
    paddingTop: 15,
  },
  cardTitleLarge: {
    color: "rgb(50,49,48)",
    fontSize: FontSizes.xLarge,
    fontWeight: FontWeights.semibold,
    marginLeft: CHILD_MARGIN,
    paddingTop: 15,
  },
  cardChildren: {
    marginLeft: CHILD_MARGIN,
    marginRight: CHILD_MARGIN,
    ":last-child": {
        marginBottom: 15,
      }
  },
});
