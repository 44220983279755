import { useEffect, useState } from "react";
import { routeDefinitions } from "router";
import { Header } from "components/AppContent";
import { AppConfigs } from "config/appConfigs";
import strings from "consts/strings";
import { DefaultButton, IDropdownOption, Link, Stack } from "@fluentui/react";
import { Card } from "components/card/Card";
import { CardStyles } from "components/card/Card.styles";
import { boldifyText } from "helpers/boldText";
import { roles } from "consts/roleToPackage";
import { NoAccessHeading } from "./components/NoAccessHeading";
import { AccessRequestStyles } from "./noAccessPage.styles";
import { RoleSelectionDropDown } from "./components/NoAccessRoleSelection";

function getMyAccessLinkAddress(role: string): string {
  return strings.noAccessPage.submitButtonNavAddress({ packageId: AppConfigs().roleConfigMap.filter((x) => role === x.role)[0].packageId });
}

export function NoAccessPage() {
  const currentRoute = routeDefinitions.DefaultRoute;
  const { main, mainStackItems, submitButtonStyle, list } = AccessRequestStyles;
  const { cardTitleLarge, cardTitleMedium } = CardStyles;
  const {
    headingDescription,
    dropDownTitle,
    linkText,
    linkAddress,
    submitButton,
    instructionTitle,
    instructionDescription,
    instructionSteps,
    instructionStepsKey,
    instructionStepsBold,
    instructionClose,
  } = strings.noAccessPage;

  const [selectedRole, setSelectedRole] = useState<IDropdownOption | undefined>(
    undefined
  );
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [myAccessAddress, setAddress] = useState<string | undefined>(undefined);
  const options: IDropdownOption[] = roles.map((x) => ({ key: x, text: x }));
  useEffect(() => {
    if (selectedRole !== undefined) {
      const buttonAddress = getMyAccessLinkAddress(selectedRole.text);
      setAddress(buttonAddress);
      setDisabled(false);
    }
  }, [selectedRole]);
  return (
    <div>
      <main>
        <Header currentRoute={currentRoute} isMsx={false} />
        <div className={main}>
          <Stack tokens={{ childrenGap: 15 }} className={mainStackItems}>
            <NoAccessHeading description={headingDescription} />
            <Card
              title={dropDownTitle}
              childGap={10}
              titleClass={cardTitleLarge}
            >
              <RoleSelectionDropDown
                setSelectedRole={setSelectedRole}
                options={options}
              />
              <Link href={linkAddress} target="_blank">
                {linkText}
              </Link>
              <DefaultButton
                className={submitButtonStyle}
                text={submitButton}
                disabled={isDisabled}
                onClick={() => window.open(myAccessAddress, "_blank")}
              />
            </Card>
            <Card
              title={instructionTitle}
              childGap={15}
              titleClass={cardTitleMedium}
            >
              {boldifyText(instructionDescription, "Submit request")}
              <ol className={list}>
                {instructionSteps.map((instruction, i) => (
                  <li key={`${instructionStepsKey}${i + 1}`}>
                    {boldifyText(instruction, instructionStepsBold[i])}
                  </li>
                ))}
              </ol>
              {instructionClose}
            </Card>
          </Stack>
        </div>
      </main>
    </div>
  );
}
