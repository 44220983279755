import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import AppContent from "components/AppContent";
import RedirectContent from "components/RedirectContent";

export function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/redirect" component={RedirectContent} />
        <Route path="/" component={AppContent} />
      </Switch>
    </Router>
  );
}
