import { Dropdown, IDropdownOption, ResponsiveMode } from "@fluentui/react";
import strings from "consts/strings";
import React from "react";
import { AccessRequestStyles } from "../noAccessPage.styles";

type RoleSelectionProps = {
  setSelectedRole: (role: IDropdownOption) => void;
  options: IDropdownOption[];
};

export function RoleSelectionDropDown(props: RoleSelectionProps) {
  const { setSelectedRole, options } = props;


  const roleSelected = (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    if (option !== undefined) {
      setSelectedRole(option);
    }
  };

  return (
    <Dropdown
      className={AccessRequestStyles.roleDropDown}
      label={strings.noAccessPage.dropDownLabel}
      options={options}
      required
      onChange={roleSelected}
      responsiveMode={ResponsiveMode.large}
      placeholder={strings.noAccessPage.dropDownPlaceHolder}
    />
  );
}
