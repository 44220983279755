import customTheme from "helpers/customTheme";
import { IRequiredConstraints } from "models/templates";

export function RequiredConstraintsList( {
	requiredConstraints
}:{
	requiredConstraints: IRequiredConstraints[]
}) {
	return (
		<ul style={customTheme.listStyle}>
			{requiredConstraints.map((item, index) => (
			<li style={customTheme.listItemStyle} key={index}>
				<p>{item?.constraintKeyDisplay}</p>
			</li>
			))}
		</ul>
	);
}


