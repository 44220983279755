import { Text } from "@fluentui/react";
import strings from "consts/strings";
import { AccessRequestStyles } from "../noAccessPage.styles";
import { IconHeading } from "./IconHeading";

export interface AccessHeadingProps {
  description: string;
}

export function NoAccessHeading(props: AccessHeadingProps) {
  const { description } = props;
  const { heading, descriptionMain, xLargeIcon, headTitle } =
    AccessRequestStyles;
  return (
    <div className={heading}>
      <div>
        <IconHeading
          iconName="ReportHacked"
          iconClassName={xLargeIcon}
          titleClassName={headTitle}
          title={strings.noAccessPage.headingTitle}
        />
        <Text className={descriptionMain}>{description}</Text>
      </div>
    </div>
  );
}
