import { Provider } from "react-redux";
import { AppRouter } from "components/AppRouter";
import "./App.css";
import { AuthClientProvider } from "services/client";
import { NoAccessPage } from "components/NoAccessPage/NoAccessPage";
import store from "./store/index";

export function App() {
  // If user has no roles redirect to no access page
  const authClient = AuthClientProvider.getInstance();
  if (authClient.getCurrentUserClaimRoles().length === 0) {
    return <NoAccessPage />
  }
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}
