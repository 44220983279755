import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ITemplateRevisionSlot, SlotInfo } from "models/slot";

import { useGetSlotsEtagQuery} from "services/slot";

const useSlotInfoProvider = (templateId:string,revisionId:string,selectedSlots:ITemplateRevisionSlot[]): SlotInfo => {
   const constraintInfoResponse = useGetSlotsEtagQuery({templateId,revisionId,selectedSlots} ?? skipToken);

  return {
    slotInfoResponse:constraintInfoResponse.data,
    isLoadingSlot:constraintInfoResponse.isLoading
  }
};

export default useSlotInfoProvider;