import { mergeStyleSets } from "@fluentui/react";
import useRouter from "hooks/useRouter";

const styles = mergeStyleSets({
  containerStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f5f5f5",
  },
  messageStyle: {
    fontSize: "24px",
    textAlign: "center",
    marginBottom: "20px",
  },
  linkStyle: {
    fontSize: "18px",
    textDecoration: "none",
    color: "blue",
    fontWeight: "normal",
    textAlign: "center",
  },
  linkContainer: {
    textAlign: "center",
  },
});

export enum DepricatedHost {
  INT = "agreementcenterv2dev.azureedge.net",
  PPE = "agreementcenterv2ppe.azureedge.net",
  PROD = "agreementcenterv2.azureedge.net",
  AIRCAPI = "agreementcenterv2aircapi.azureedge.net",
}

// eslint-disable-next-line
const RedirectContent = () => {
  const { getHostName } = useRouter();

  const newLink = () => {
    switch (getHostName()) {
      case DepricatedHost.INT:
        return "https://dev.agreements.azure-test.net";
      case DepricatedHost.PPE:
        return "https://test.agreements.azure-test.net"
      case DepricatedHost.AIRCAPI:
        return "https://aircapi.agreements.azure-test.net";
      case DepricatedHost.PROD:
        return "https://agreements.microsoft.com";
      default:
        return "https://dev.agreements.azure-test.net";
    }
  };
  return (
    <div className={styles.containerStyle}>
      <div>
        <h1 className={styles.messageStyle}>This URL is deprecated.</h1>
        <p>Please click on the following link to access the new URL:</p>
        <div className={styles.linkContainer}>
          <a
            className={styles.linkStyle}
            href={newLink()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {newLink()}
          </a>
        </div>
      </div>
    </div>
  );
};

export default RedirectContent;
