export const roles = [
  "China 21V - Content Manager",
  "New Commerce - Content Manager",
  "OEM - Content Manager",
  "Professional Services - Content Manager",
  "View Agreement Content - China21V",
  "View Agreement Content - Commerce",
  "View Agreement Content - OEM",
  "View Agreement Content - Professional Services"
] as const;
