import { Checkbox } from "@fluentui/react";
import { IConstraintValue } from "models/constraints";
import { ISlotConstraint } from "models/slot";
import { useEffect } from "react";

export interface ICustomCheckBox {
  item: ISlotConstraint;
  checked: boolean;
  label: string | undefined;
  onChangeCheckbox?: (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => void;
  id?: string;
  title?: string;
  name?: string;
  triggerNoValues: (itemId?: ISlotConstraint, isInclude?: boolean) => void;
  constraintValues?: IConstraintValue[];
}

function CustomCheckBox({
  item,
  checked,
  label,
  name,
  title,
  onChangeCheckbox = () => {},
  id,
  triggerNoValues = () => {},
  constraintValues,
}: ICustomCheckBox) {
  useEffect(() => {
    if (constraintValues) {
      const opts = constraintValues?.map((value: IConstraintValue) => ({
        id: value.id,
        key: value.name,
        text: value.display === "" ? value.name : value.display,
      }));
      if (!opts) triggerNoValues(item, true);
    }
  }, [constraintValues]);

  return (
    <Checkbox
      label={label}
      checked={checked}
      key={id}
      id={id}
      name={name}
      onChange={onChangeCheckbox}
      title={title}
    />
  );
}

export default CustomCheckBox;
