/* eslint-disable no-param-reassign */

import { AnyAction } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ROOT_NODE_ID } from "consts/globals";
import { deleteSlotTree, isSlotGroup, setSlotLevels } from "helpers/slot";
import _ from "lodash";

import {
  ISlotsCreationInfo,
  ITemplateRevisionSlot,
  ISlotsUpdateInfo,
  ISlotsDeletionInfo,
  ISlotTree,
  ITemplateRevisionSlotItem,
  ISlotUpdateInfo,
  ISlotIdentificationVal,
  Slot,
} from "models/slot";
import { ThunkAction } from "redux-thunk";
import { RootState } from "store";
import { baseQuery, baseQueryWithRetry } from "./baseQuery";

export const slotApi = createApi({
  reducerPath: "slotApi",
  baseQuery: baseQuery(),
  tagTypes: ["slotTree", "slotTranslations","slotWithEtag"],
  endpoints: (builder) => ({
    getSlotTranslations: builder.query<
      ITemplateRevisionSlotItem[],
      { templateId: string; revisionId: string; query?: string }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const { templateId, revisionId, query = "" } = _arg;

        const result = await fetchWithBQ(
          `template/${templateId}/revision/${revisionId}/slots${
            query ? `?${query} ` : ""
          }`
        );

        const tmpSlots = (result.data || []) as ITemplateRevisionSlotItem[];
        setSlotLevels(tmpSlots);
        return result as any;
      },
      providesTags: ["slotTranslations"],
    }),
    getSlotTree: builder.query<
      ISlotTree,
      { templateId: string; revisionId: string}
    >({
      queryFn: async (
        args,
        { getState, endpoint },
        _extraOptions,
        fetchWithBQ
      ) => {
        const cacheKey = `${endpoint}(${JSON.stringify(
          args,
          Object.keys(args).sort()
        )})`;
        const cachedSlotTree = (getState() as any).slotApi.queries[cacheKey]
          .data as ISlotTree;

        const clonedSlotTree = _.cloneDeep(cachedSlotTree) || {
          isLoading: false,
          childNodeMappings: {},
          nodes: {},
        };

        const getSlot =  (
          slotVal:ITemplateRevisionSlot
       ) => {
          const slot:ITemplateRevisionSlot= clonedSlotTree.nodes[slotVal.id];
          if (!slot) {
            slotVal.parentSlotId = slotVal.parentSlotId || ROOT_NODE_ID; // Root Level slots returned from API don't have parent slot ID set
            clonedSlotTree.nodes[slotVal.id] = slotVal;
          }

          return slotVal;
        };

        const extractChildSlots = (
          data: any,
          slotId?: string
        ): ITemplateRevisionSlot[] => {
          if (slotId) {
            const childSlotsInfo = (data || { slots: [] }) as {
              slots: ITemplateRevisionSlot[];
            };
            return childSlotsInfo.slots;
          }

          const slots = (data || []) as ITemplateRevisionSlot[];
          return slots;
        };

        const getChildSlots = async ({
          templateId,
          revisionId,
          slot,
        }: ISlotIdentificationVal) => {
          const slotCacheKey = slot.id || ROOT_NODE_ID;
          let childSlotIds = clonedSlotTree.childNodeMappings[slotCacheKey];
          let slots:ITemplateRevisionSlot[]=[];
          let dataCopy;
          
          if(Object.keys(slot).length===0 ){
             const {data}  = await fetchWithBQ(
              `template/${templateId}/revision/${revisionId}/slots/`
            );
            dataCopy=data;
          }
          if(!dataCopy){
            if(Object.keys(slot).length===0){
              dataCopy=null;
            }else{
              dataCopy=slot;
            }

          }
          if (!childSlotIds) {
            if(Object.keys(slot).length===0){
              slots = extractChildSlots(dataCopy, "");
            }else{
              slots = extractChildSlots(dataCopy, slot.id);
            }
             
            childSlotIds = slots.map((s) => s.id);
            if (slots.length > 0) {
              clonedSlotTree.childNodeMappings[slotCacheKey] = childSlotIds;
            }
  
          }
          const slotPromises = slots.map((slotVal) =>
          getSlot(slotVal)
        );
          const slotsPromise = await Promise.all(slotPromises);
          return slotsPromise;
        };


        const buildSlotTree = async (
          slotIdentification: ISlotIdentificationVal,
          rootSlot: ITemplateRevisionSlot | undefined
        ) => {
          if (!rootSlot || isSlotGroup(rootSlot)) {
            const childSlots = await getChildSlots(slotIdentification);
            const buildSubTreePromises = childSlots.map((slot) => {
              const { templateId, revisionId } = slotIdentification;
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              return buildSlotTree(
                { templateId, revisionId, slot },
                slot
              );
            });

            await Promise.all(buildSubTreePromises);
          }
        };

        clonedSlotTree.childNodeMappings={};
        clonedSlotTree.nodes={};
          await buildSlotTree({ ...args, slot: {} as ITemplateRevisionSlot }, undefined);
          clonedSlotTree.isLoading = false;
        return { data: clonedSlotTree };
      },
      providesTags: [{ type: "slotTree" }],
    }),
    getSlotEtag: builder.query<Slot, {templateId?: string; revisionId?: string,slotId?:string }>({
      queryFn: async (
        { templateId, revisionId, slotId},
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const { data, meta } = await fetchWithBQ(
          `template/${templateId}/revision/${revisionId}/slots/${slotId}`
        );

        const slotData = data! as ITemplateRevisionSlot;
        slotData.etag = meta?.response?.headers.get("etag") as string;
         const slot:Slot={data:slotData,isLoading:false};
        return {
          data:slot
        };
      },
      providesTags: (_result, _error, arg) => [
        { type: "slotWithEtag", templateId: arg.templateId,revisionId:arg.revisionId,slotId:arg.slotId },
      ],
    }),
    getSlotsEtag: builder.query<ITemplateRevisionSlot[], {templateId?: string; revisionId?: string,selectedSlots?:ITemplateRevisionSlot[] }>({
      queryFn: async (
        { templateId, revisionId, selectedSlots},
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const slotWithEtagPromises = selectedSlots!.map((slot) =>
        fetchWithBQ(`template/${templateId}/revision/${revisionId}/slots/${slot.id}`)
      );
      const slotEtagPromisesResults = await Promise.all(
        slotWithEtagPromises
      );

      const slots = slotEtagPromisesResults.map((result) => {
        const slot = result.data as ITemplateRevisionSlot;
        slot.etag = result.meta?.response?.headers.get("etag") as string;
        return slot;
      });


        return {
          data:slots as ITemplateRevisionSlot[]
        };
      },
       providesTags: ["slotWithEtag"],

    }),
    updateSlots: builder.mutation<{}, ISlotsUpdateInfo>({
      queryFn: async (args, api, _extraOptions, baseQueryAgr) => {
        const { templateId, revisionId, updateInfos, parentSlotId } = args;
       
        const sendUpdateRequest = async ({
          slotId,
          etag,
          updateOperations,
        }: ISlotUpdateInfo) => {
          
          const url = `template/${templateId}/revision/${revisionId}/slots/${slotId}`;
          const updateRequest = await baseQueryAgr({
            url,
            method: "PATCH",
            headers: { "if-match": etag },
            body: updateOperations,
          });
          if (updateRequest.data) {
            const expectedEtag = updateRequest?.meta?.response?.headers.get(
              "etag"
            ) as string;
            const verificationResult = await baseQueryWithRetry(
              {
                url,
                method: "GET",
                validateStatus: (response) => {
                  const newEtag = response.headers.get("etag")!;
                  return expectedEtag === newEtag;
                },
              },
              api,
              { maxRetries: 2 }
            );

            return verificationResult;
          }

          return updateRequest;
        };


        const updateRequestPromises = updateInfos.map((updateInfo) =>
          sendUpdateRequest(updateInfo)
        );
        const updateRequests = await Promise.all(updateRequestPromises);
        if (!updateRequests.every((r) => r.data)) {
          return { error: updateRequests.find((r) => r.error)!.error! };
        }
        api.dispatch(
          slotApi.util.updateQueryData(
            "getSlotTree",
            { templateId, revisionId },
            (state) => {
              state.isLoading = true;
               delete state.childNodeMappings[parentSlotId];
              // eslint-disable-next-line array-callback-return, @typescript-eslint/no-unused-expressions
             
             
              updateInfos.forEach((updateInfo) => {
                  delete state.childNodeMappings[updateInfo.parentSlotId];
                 delete state.nodes[updateInfo.slotId]
                 // state.nodes[updateInfo.slotId]=updateRequests[0].data as ITemplateRevisionSlot;
              });

            }
          )
        );

        return { data: {} };
        
      },
      
       invalidatesTags: ['slotWithEtag'],
      extraOptions: { maxRetries: 3 },
    }),
    createSlots: builder.mutation<{ id: string }[], ISlotsCreationInfo>({
      queryFn: async (
        creationInfo,
        { dispatch },
        _extraOptions,
        baseQueryAgr
      ) => {
        const { templateId, revisionId, parentSlotId } = creationInfo;
        const slotCreationRequestPromises = creationInfo.slots.map((slotInfo) =>
          baseQueryAgr({
            url: `template/${templateId}/revision/${revisionId}/slots`,
            method: "POST",
            body: slotInfo,
          })
        );

        const slotCreationRequests = await Promise.all(
          slotCreationRequestPromises
        );
        if (!slotCreationRequests.every((r) => r.data)) {
          return { error: slotCreationRequests.find((r) => r.error)!.error! };
        }

        dispatch(
          slotApi.util.updateQueryData(
            "getSlotTree",
            { templateId, revisionId },
            (state) => {
              state.isLoading = true;
              delete state.childNodeMappings[parentSlotId];
            }
          )
        );

        const slotIds = slotCreationRequests.map(
          (r) => r.data! as { id: string }
        );
        return { data: slotIds };
      },
    }),
    deleteSlots: builder.mutation<{}, ISlotsDeletionInfo>({
      queryFn: async (
        deletionInfo,
        { dispatch },
        _extraOptions,
        baseQueryAgr
      ) => {
        const { templateId, revisionId, slotInfos } = deletionInfo;
        const deletionRequestPromises = slotInfos.map((slotInfo) =>
          baseQueryAgr({
            url: `template/${templateId}/revision/${revisionId}/slots/${slotInfo.slotId}`,
            method: "DELETE",
          })
        );

        const deletionResponses = await Promise.all(deletionRequestPromises);
        if (!deletionResponses.every((r) => r.data)) {
          return { error: deletionResponses.find((r) => r.error)!.error! };
        }

        dispatch(
          slotApi.util.updateQueryData(
            "getSlotTree",
            { templateId, revisionId },
            (state) => {
              state.isLoading = true;
              slotInfos.forEach((slotInfo) => {
                delete state.childNodeMappings[slotInfo.parentSlotId];
                deleteSlotTree(state, slotInfo.slotId);
              });
            }
          )
        );

        return { data: {} };
      },
    }),
  }),
});

export const {
  useGetSlotTranslationsQuery,
  useLazyGetSlotTranslationsQuery,
  useGetSlotTreeQuery,
  useGetSlotEtagQuery,
  useGetSlotsEtagQuery,
  useLazyGetSlotTreeQuery,
  useLazyGetSlotEtagQuery,
  useLazyGetSlotsEtagQuery,
  useUpdateSlotsMutation,
  useCreateSlotsMutation,
  useDeleteSlotsMutation,
} = slotApi;

export const invalidateSlotCacheThunk =
  (
    partId: string,
    templateId: string,
    revisionId: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(
      slotApi.util.updateQueryData(
        "getSlotTree",
        { templateId, revisionId },
        (state) => {
          const nodeList = Object.values(state.nodes);
          const targetSlot = nodeList.find((n) => n.partId === partId);
          if (targetSlot) {
            state.isLoading = true;
            delete state.nodes[targetSlot.id];
          }
        }
      )
    );
    dispatch(slotApi.util.invalidateTags([{ type: "slotTree" }]));
  };
