import { IConfig } from "types/Config.types";

const devAppConfig: IConfig = {
	authentication: {
		msalConfig: {
		  auth: {
			clientId: "32753538-fe99-46ef-92a9-4abcfaeb7f16",
			authority:
			  "https://login.microsoftonline.com/microsoft.onmicrosoft.com",
			redirectUri: window.location.origin,
			knownAuthorities: ["login.microsoftonline.com"],
		  },
		  cache: {
			cacheLocation: "sessionStorage",
			storeAuthStateInCookie: false,
		  },
		},
		scopeTemplateService: "d442c179-0c8a-41ae-84e9-35ed5e56eb1f/Template.ReadWriteAll",
		scopeDocumentService: "d98a4d19-79f4-4b0f-bbf3-ef527fef3797/Document.ReadWriteAll",		
	  },
	  rootRouteRegex: /^((?:\/)(?:main.aspx)?)/gim,
	  templateServiceBaseUrl: "https://template.int.l2o.microsoft.com/v1",
	  wopiHostBaseUrl: "https://wopihost.int.l2o.microsoft.com/v1",
	  instrumentationKey: "46deb58f-e9d7-4a23-b8ec-a8fb7c408fea",
	  msxHostnames: [
		"msxplatform.crm.dynamics.com",
		"msxsupportpoc.crm.dynamics.com",
	  ],
	  customClauseTemplateInfo: [
		{
		  key: "8d5ad5f3-5da0-401a-9f08-0a6810f9b11b",
		  text: "Microsoft Customer Agreement",
		},
		{
		  key: "02eb1b33-2c00-4bb1-b73d-2b9c18a92196",
		  text: "Customer Affiliate Purchase Terms for MCA (Test)",
		},
	  ],
	  psCategoryTagInfo: {
		id: "1df51f7b-e4e2-44f5-afd1-1d19950e0acb",
		name: "Professional Services",
	  },
	  businessUnitFeatureFlagging: true,
	  isEnabled: "true",
	  tenant: "microsoft.onmicrosoft.com",
	  roleConfigMap: [
		{
		  role: "China 21V - Content Manager",
		  packageId: "0b7ab0a0-d10d-46a7-878a-0f0c11338dd0",
		},
		{
		  role: "New Commerce - Content Manager",
		  packageId: "bf2df218-c13a-439a-87ff-ce321ab91c54",
		},
		{
		  role: "OEM - Content Manager",
		  packageId: "471f4320-89ca-45fd-8e58-0a4d0a4a88cb",
		},
		{
		  role: "Professional Services - Content Manager",
		  packageId: "554253b7-f522-4b26-a634-3dd3eef40882",
		},
		{
		  role: "View Agreement Content - China21V",
	      packageId: "ff9c61c5-cb1f-47e2-ac69-a137e45ebd79",
		},
		{
		  role: "View Agreement Content - Commerce",
		  packageId: "f87cda2b-d7c9-4585-93dc-2224d229a455",
		},
		{
		  role: "View Agreement Content - OEM",
		  packageId: "224a7b21-2492-4f25-9c0a-b9082bbdfd47",
		},
		{
		  role: "View Agreement Content - Professional Services",
		  packageId: "63366393-26d2-4fe4-996e-9346b00dfacf",
		},
	  ]
};

export function AppConfigs(): IConfig {
	if (process.env.REACT_APP_NODE_ENV !== "prod" && process.env.REACT_APP_NODE_ENV !== "int") {
		return devAppConfig;
	}
	return window.appConfig;
}