import { routeDefinitions } from "router";
import { Header } from "components/AppContent";
import strings from "consts/strings";

export function UnavailablePage() {
	const currentRoute = routeDefinitions.DefaultRoute;
	return (
		<div>
			<main>
				<Header currentRoute={currentRoute} isMsx={false} />
				{strings.agreementNotAvailable}
			</main>
		</div>
	);
}


