import { UnavailablePage } from "components/UnavailablePage/UnavailablePage";
import ReactDOM from "react-dom";
import { mergeStyles } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import TrackingContext from "components/shared/TrackingContext";
import ErrorMessage from "components/errors/ErrorMessage";
import AuthenticationContext from "components/shared/AuthenticationContext";
import customTheme from "helpers/customTheme";
import { IConfig } from "types/Config.types";
import { AppConfigs } from "config/appConfigs";
import { App } from "./App";
import "./index.css";
import { TelemetryContextProvider } from "./services/telemetry";

declare global {
  interface Window {
    MsxAuthenticationService: any;
    appConfig: IConfig;
  }
}
function AppProvider() {
  const instance= TelemetryContextProvider.getAppInsightInfo()
	if (AppConfigs().isEnabled === "true") {
    return (
      <AppInsightsErrorBoundary onError={ErrorMessage} appInsights={instance.getReactPluginInstance()}>
        <TrackingContext appInsights={instance.getAppInsightInfoInstance()}>
          <AuthenticationContext>
            <App />
          </AuthenticationContext>
        </TrackingContext>
      </AppInsightsErrorBoundary>
    );
  }
    return (
        <UnavailablePage />
    );
}

function loadApp(): void {
  // Inject some global styles - workaround for msx not loading app css
  mergeStyles({
    ":global(body,html)": {
      margin: 0,
      padding: 0,
      height: "100vh",
    },
    ":global(main)": {
      width: "100%",
      height: "100%",
    },
    ":global(*)": {
      boxSizing: "border-box",
    },
    ":global(#contentWrapper)": {
      color: customTheme.bodyColor,
      fontFamily:
        "'Segoe UI', '-apple-system', BlinkMacSystemFont, Roboto,'Helvetica Neue', Helvetica, Ubuntu, Arial, sans-serif, 'Apple Color Emoji','Segoe UI Emoji', 'Segoe UI Symbol'",
    },
  });

  initializeIcons();
  ReactDOM.render(<AppProvider />, document.getElementById("contentWrapper"));
}
loadApp();
export default AppProvider;
