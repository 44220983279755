import { ReactNode } from "react";

export function boldifyText(item: string, boldText?: string): ReactNode | string {
    if(!item){
        return "";
    } 
    if(boldText && boldText.length > 0){

        const index = item.indexOf(boldText);
        if (index > -1) {
            const endIndex = index + boldText.length;
            return (
                <span>
                    {item.slice(0, index)}
                    <b>{item.slice(index, endIndex)}</b>
                    {item.slice(endIndex)}
                </span>
            );
        }
        return item;
    }
    return item;
}
